function getModalContent(modal) {
  return modal.querySelector('#QuickAddInfo-convermax');
}

export function hideModal() {
  const modal = window.document.querySelector('#QuickAdd-convermax');
  const modalContent = getModalContent(modal);

  document.body.classList.remove('overflow-hidden');

  modal.removeAttribute('open');
  modalContent.innerHTML = '';
}

export function showModal(e) {
  const modal = window.document.querySelector('#QuickAdd-convermax');
  const opener = e.target.closest('button');

  opener.setAttribute('aria-disabled', true);
  opener.classList.add('loading');
  opener.querySelector('.loading__spinner').classList.remove('hidden');

  fetch(opener.getAttribute('data-product-url'))
    .then((response) => response.text())
    .then((responseText) => {
      const productElement = new DOMParser()
        .parseFromString(responseText, 'text/html')
        .querySelector('product-info');

      preprocessHTML(modal, productElement);

      HTMLUpdateUtility.setInnerHTML(getModalContent(modal), productElement.innerHTML);

      if (window.Shopify && Shopify.PaymentButton) {
        Shopify.PaymentButton.init();
      }

      if (window.ProductModel) {
        window.ProductModel.loadShopifyXR();
      }

      document.body.classList.add('overflow-hidden');

      window.document.body.addEventListener('click', (event) => {
        if (!event.target.closest('#QuickAdd-convermax .quick-add-modal__content')) {
          hideModal();
        }
      });

      window.document.body.addEventListener('keyup', (event) => {
        if (event.code.toUpperCase() === 'ESCAPE') {
          hideModal();
        }
      });
    })
    .finally(() => {
      opener.removeAttribute('aria-disabled');
      opener.classList.remove('loading');
      opener.querySelector('.loading__spinner').classList.add('hidden');
      modal.setAttribute('open', true);
    });
}

function preprocessHTML(modal, productElement) {
  const modalContent = getModalContent(modal);

  productElement.classList.forEach((classApplied) => {
    if (classApplied.startsWith('color-') || classApplied === 'gradient') {
      modalContent.classList.add(classApplied);
    }
  });

  preventDuplicatedIDs(productElement);
  removeDOMElements(productElement);
  removeGalleryListSemantic(productElement);
  updateImageSizes(productElement);
  preventVariantURLSwitching(productElement);
}

function preventVariantURLSwitching(productElement) {
  const variantPicker = productElement.querySelector('variant-selects');
  if (variantPicker) variantPicker.setAttribute('data-update-url', 'false');
}

function removeDOMElements(productElement) {
  const pickupAvailability = productElement.querySelector('pickup-availability');
  if (pickupAvailability) {
    pickupAvailability.remove();
  }

  const productModal = productElement.querySelector('product-modal');
  if (productModal) {
    productModal.remove();
  }

  const modalDialogs = productElement.querySelectorAll('modal-dialog');
  if (modalDialogs) {
    modalDialogs.forEach((modal) => modal.remove());
  }
}

function preventDuplicatedIDs(productElement) {
  const sectionId = productElement.dataset.section;

  if (sectionId) {
    productElement.innerHTML = productElement.innerHTML.replaceAll(sectionId, `quickadd-${sectionId}`);

    productElement.querySelectorAll('variant-selects, product-info').forEach((element) => {
      element.dataset.originalSection = sectionId;
    });
  }
}

function removeGalleryListSemantic(productElement) {
  const galleryList = productElement.querySelector('[id^="Slider-Gallery"]');

  if (galleryList) {
    galleryList.setAttribute('role', 'presentation');
    galleryList.querySelectorAll('[id^="Slide-"]').forEach((li) => li.setAttribute('role', 'presentation'));
  }
}

function updateImageSizes(productElement) {
  const product = productElement.querySelector('.product');

  if (product) {
    if (!product.classList.contains('product--columns')) {
      return;
    }

    const mediaImages = product.querySelectorAll('.product__media img');
    if (!mediaImages.length) {
      return;
    }

    let mediaImageSizes =
      '(min-width: 1000px) 715px, (min-width: 750px) calc((100vw - 11.5rem) / 2), calc(100vw - 4rem)';

    if (product.classList.contains('product--medium')) {
      mediaImageSizes = mediaImageSizes.replace('715px', '605px');
    } else if (product.classList.contains('product--small')) {
      mediaImageSizes = mediaImageSizes.replace('715px', '495px');
    }

    mediaImages.forEach((img) => img.setAttribute('sizes', mediaImageSizes));
  }
}
