export default {
  SEARCH_BOX_CLASS: 'search search-modal__form',
  SEARCH_BOX_INPUT_CLASS: 'search__input field__input',
  SEARCH_BOX_BUTTON_CLASS: 'search__button field__button',
  SEARCH_BOX_SECTION_TITLE: "{this.sectionTitle ? this.sectionTitle : 'Suggestions'}",
  BROWSE_ALL: 'Search For "{this.query}"',
  SEARCH_DROPDOWN_IMAGE_CLASS: 'predictive-search__image',
  AUTOCOMPLETE_PRODUCT_TITLE_CLASS: 'predictive-search__item-heading h5',
  SEARCH_BOX_CLEAR_BUTTON: '✕',

  CURRENT_SEARCH: 'Filter by',
  FACET_TITLE_ICON: `
    <span rt-if="this.selection.length"> ({{selection.length}})</span>
    <rt-virtual rt-if="this.isCollapsed" template="icons/plus" />
    <rt-virtual rt-if="!this.isCollapsed" template="icons/minus" />`,

  FILTERS_TEXT: 'Filter by',

  GO_TEXT: 'Search',
};
