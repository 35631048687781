
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchBoxDialogRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog' }, _createElement('div', { 'className': 'clear-self cm_search-box_container' }, _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'search search-modal__form dialog-content',
        'onSubmit': this.preventFormSubmission
    }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title' }, 'Search'), _createElement('div', {
        'className': 'cm_search-box-dialog_close-button close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' })))), _createElement('div', { 'className': 'cm_search-box_form-container' }, [
        [this.searchInput(function () {
                return _createElement('div', { 'className': 'search__input field__input cm_searchInput' }, window.Convermax.config?.searchBoxPlaceholderText || 'Search');
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('span', {
            'className': 'cm_search-box_clear-container',
            'key': '6062'
        }, this.inputNotEmpty ? _createElement('span', {
            'className': 'cm_search-box_clear',
            'onClick': this.clearInput,
            'key': '975'
        }, '\n    \u2715\n  ') : null),
        _createElement('button', {
            'type': 'button',
            'title': 'Search',
            'aria-label': 'search button',
            'className': 'cm_search-box_submit search__button field__button',
            'data-cm-role': 'add-query',
            'key': '6064'
        }, [_createElement('svg', {
                'className': 'icon icon-search',
                'aria-hidden': 'true',
                'focusable': 'false',
                'key': '12460'
            }, _createElement('use', { 'href': '#icon-search' }))])
    ]), [this.dropdown(function () {
            function repeatSections1(sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    return this.template === 'product' ? _createElement('li', {
                                        'className': 'predictive-search__list-item cmTemplate_product',
                                        'role': 'option',
                                        'aria-selected': 'false'
                                    }, _createElement('div', {
                                        'className': 'predictive-search__item predictive-search__item--link-with-thumbnail link link--text',
                                        'tabIndex': '-1'
                                    }, _createElement('img', {
                                        'className': 'predictive-search__image',
                                        'src': this.imageOrDefault(this.image),
                                        'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.title)),
                                        'onError': this.onImageError,
                                        'width': '50',
                                        'height': '27.8'
                                    }), _createElement('div', { 'className': 'description-block predictive-search__item-content predictive-search__item-content--centered' }, _createElement('p', mergeProps_product({ 'className': 'predictive-search__item-heading h5' }, { dangerouslySetInnerHTML: { __html: this.title } }))))) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return this.sectionTitle.includes('Products') && this.items.length > 0 ? _createElement('div', { 'key': '7' }, _createElement('h2', { 'className': 'item section-title predictive-search__heading text-body caption-with-letter-spacing' }, '\n          ', this.sectionTitle ? this.sectionTitle : 'Suggestions', '\n        '), _createElement.apply(this, [
                            'ul',
                            { 'className': 'predictive-search__results-list list-unstyled cmRepeater_items' },
                            _map(this.items, repeatItems1.bind(this))
                        ])) : null;
                    }, { count: undefined })];
            }
            function repeatSections2(sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    return this.template === 'text' ? _createElement('div', { 'className': 'cmTemplate_text' }, _createElement('li', {
                                        'className': 'predictive-search__list-item',
                                        'role': 'option',
                                        'aria-selected': 'false'
                                    }, _createElement('div', { 'className': 'predictive-search__item link link--text' }, _createElement('div', { 'className': 'predictive-search__item-content predictive-search__item-content--centered' }, _createElement('p', { 'className': 'predictive-search__item-heading predictive-search__item-query-result h5' }, _createElement('mark', { dangerouslySetInnerHTML: { __html: this.textHighlight } })))))) : this.template === 'product' ? _createElement('li', {
                                        'className': 'predictive-search__list-item cmTemplate_product',
                                        'role': 'option',
                                        'aria-selected': 'false'
                                    }, _createElement('div', {
                                        'className': 'predictive-search__item predictive-search__item--link-with-thumbnail link link--text',
                                        'tabIndex': '-1'
                                    }, _createElement('img', {
                                        'className': 'predictive-search__image',
                                        'src': this.imageOrDefault(this.image),
                                        'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.title)),
                                        'onError': this.onImageError,
                                        'width': '50',
                                        'height': '27.8'
                                    }), _createElement('div', { 'className': 'description-block predictive-search__item-content predictive-search__item-content--centered' }, _createElement('p', mergeProps_product({ 'className': 'predictive-search__item-heading h5' }, { dangerouslySetInnerHTML: { __html: this.title } }))))) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return this.items.length > 0 && (!this.sectionTitle.includes('Products') || window.innerWidth <= 749) ? _createElement('div', { 'key': '7' }, _createElement('h2', { 'className': 'item section-title predictive-search__heading text-body caption-with-letter-spacing' }, '\n          ', this.sectionTitle ? this.sectionTitle : 'Suggestions', '\n        '), _createElement.apply(this, [
                            'ul',
                            { 'className': 'predictive-search__results-list list-unstyled cmRepeater_items' },
                            _map(this.items, repeatItems1.bind(this))
                        ])) : null;
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'dialog-body cm_autocomplete cm_dropdown' }, _createElement('div', { 'className': 'cm_dropdown__inner-wrapper predictive-search predictive-search--header' }, _createElement('div', { 'className': 'cmRepeater_sections-container' }, _createElement.apply(this, [
                'div',
                { 'className': 'predictive-search__result-group cmRepeater_sections-products' + (window.innerWidth <= 749 ? ' cm_hide' : '') + ' cmRepeater_sections' },
                _map(this.sections, repeatSections1.bind(this))
            ]), _createElement.apply(this, [
                'div',
                { 'className': 'predictive-search__result-group cmRepeater_sections-other cmRepeater_sections' },
                _map(this.sections, repeatSections2.bind(this))
            ])), this.browseAllButton ? [[this.browseAllButton(function () {
                        return _createElement('a', { 'className': 'item button-item predictive-search__item predictive-search__item--term link link--text h5 animate-arrow cm_browseAllButton' }, _createElement('span', {}, ' Search For "', this.query, '" '), _createElement('span', {}, _createElement('svg', {
                            'aria-hidden': 'true',
                            'focusable': 'false',
                            'className': 'icon icon-arrow',
                            'viewBox': '0 0 6 11',
                            'fill': 'none',
                            'xmlns': 'http://www.w3.org/2000/svg'
                        }, _createElement('path', {
                            'd': 'M1 1L4 5.5L1 10',
                            'stroke': 'currentColor',
                            'strokeWidth': '2'
                        }))));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]] : null));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))));
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown"]