
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'grid__item scroll-trigger animate--slide-in scroll-trigger--cancel' }, _createElement('div', { 'className': 'card-wrapper underline-links-hover ' + (this.is_article ? 'article-card-wrapper' : 'product-card-wrapper') }, _createElement('div', { 'className': 'card card--standard' + (this.is_article ? ' article-card' : '') }, _createElement('div', { 'className': 'card__inner color-scheme-3 gradient ratio' }, _createElement('div', { 'className': 'card__media' + (this.is_article ? ' article-card__image-wrapper' : '') }, _createElement('div', { 'className': 'media media--transparent media--hover-effect' + (this.is_article ? ' article-card__image' : '') }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'className': 'motion-reduce',
                    'width': '1920',
                    'height': '1876'
                }))), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__badge top left' }, this.out_of_stock ? _createElement('span', {
                    'className': 'badge badge--bottom-left',
                    'key': '942'
                }, 'Sold Out') : null, this.on_sale ? _createElement('span', {
                    'className': 'badge badge--bottom-left sale-badge',
                    'key': '1035'
                }, 'Sale') : null, this.is_article ? _createElement('span', {
                    'className': 'badge badge--bottom-left blog-badge',
                    'key': '1130'
                }, 'Blog') : null))), _createElement('div', { 'className': 'card__content' }, this.sku ? _createElement('div', {
                    'className': 'part-number',
                    'key': '1303'
                }, _createElement('span', { dangerouslySetInnerHTML: { __html: this.sku } })) : null, this.is_article ? _createElement('div', {
                    'className': 'article-card__info caption-with-letter-spacing h5',
                    'key': '1445'
                }, _createElement('span', { 'className': 'circle-divider' }, _createElement('time', { 'dateTime': 'this.published_at' }, '\n              ', new Date(this.published_at).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }), '\n            '))) : null, _createElement('div', { 'className': 'card__information' }, _createElement('h3', { 'className': 'card__heading h5' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'id': 'CardLink-template--cm-collection-list-' + this.id,
                    'className': 'full-unstyled-link',
                    'aria-labelledby': 'CardLink-template--cm-collection-list-' + this.id + ' Badge-template--cm-collection-list-' + this.id
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'card-information' }, _createElement('span', { 'className': 'caption-large light' }), _createElement('div', { 'className': 'price' + (this.on_sale ? ' price--on-sale' : '') }, _createElement('div', { 'className': 'price__container' }, _createElement('div', { 'className': 'price__regular' }, _createElement('span', { 'className': 'price-item price-item--regular' }, this.price_varies ? _createElement('span', { 'key': '2604' }, 'From: ') : null, '\n                    ', this.formatPrice(this.price), '\n                  ')), _createElement('div', { 'className': 'price__sale' }, _createElement('span', {}, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price))), _createElement('span', { 'className': 'price-item price-item--sale price-item--last' }, this.price_varies ? _createElement('span', { 'key': '3049' }, 'From: ') : null, '\n                    ', this.formatPrice(this.price), '\n                  ')))))), !this.is_article ? _createElement('div', {
                    'className': 'quick-add no-js-hidden',
                    'key': '3275'
                }, !window.Convermax.customerCompanyId && this.variant_ids.length === 1 && !this.out_of_stock && !this.buyable ? _createElement('a', {
                    'href': '/pages/dealer-locator',
                    'className': 'quick-add__submit button btn-with-icon find-dealer-button',
                    'key': '3347'
                }, _createElement('svg', {
                    'width': '11',
                    'height': '12',
                    'viewBox': '0 0 11 12',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg'
                }, _createElement('circle', {
                    'cx': '4.75',
                    'cy': '5.25',
                    'r': '3.75',
                    'stroke': 'currentColor',
                    'strokeWidth': '1.5'
                }), _createElement('path', {
                    'd': 'M7.75 8.25L10 10.5',
                    'stroke': 'currentColor',
                    'strokeWidth': '1.5'
                })), '\n            Find a Dealer\n          ') : null, (!!window.Convermax.customerCompanyId || this.buyable) && !this.out_of_stock && this.variant_ids.length === 1 ? _createElement('product-form', { 'key': '3942' }, _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'id': this.id,
                    'accept-charset': 'UTF-8',
                    'className': 'form',
                    'encType': 'multipart/form-data',
                    'noValidate': 'novalidate',
                    'data-type': 'add-to-cart-form'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'form_type',
                    'value': 'product'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'utf8',
                    'value': '\u2713'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0],
                    'className': 'product-variant-id'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'product-id',
                    'value': this.id
                }), _createElement('button', {
                    'type': 'submit',
                    'name': 'add',
                    'className': 'quick-add__submit button button--full-width button--secondary btn-with-icon',
                    'aria-haspopup': 'dialog',
                    'aria-live': 'polite',
                    'data-sold-out-message': 'true'
                }, _createElement('span', {}, '\n                  Add to cart\n                  ', _createElement('svg', {
                    'width': '9',
                    'height': '8',
                    'viewBox': '0 0 9 8',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg'
                }, _createElement('path', {
                    'd': 'M4.5 0V8',
                    'stroke': 'currentColor',
                    'stroke-width': '1.5'
                }), _createElement('path', {
                    'd': 'M0.5 4L8.5 4',
                    'stroke': 'currentColor',
                    'stroke-width': '1.5'
                }))), _createElement('div', { 'className': 'loading__spinner hidden' }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'className': 'spinner',
                    'viewBox': '0 0 66 66',
                    'xmlns': 'http://www.w3.org/2000/svg'
                }, _createElement('circle', {
                    'className': 'path',
                    'fill': 'none',
                    'stroke-width': '6',
                    'cx': '33',
                    'cy': '33',
                    'r': '30'
                })))))) : null, this.variant_ids.length > 1 && !this.out_of_stock ? _createElement('modal-opener', {
                    'data-modal': '#QuickAdd-' + this.id,
                    'key': '5582'
                }, !!window.Convermax.customerCompanyId || this.buyable ? _createElement('button', {
                    'onClick': e => window.Convermax.quickView(e),
                    'type': 'submit',
                    'name': 'add',
                    'className': 'quick-add__submit button button--full-width button--secondary choose-option-btn btn-with-icon',
                    'aria-haspopup': 'dialog',
                    'data-product-url': this.url,
                    'data-modal': '#PopupModal',
                    'key': '5711'
                }, '\n              Choose options\n              ', _createElement('svg', {
                    'width': '9',
                    'height': '8',
                    'viewBox': '0 0 9 8',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg'
                }, _createElement('path', {
                    'd': 'M4.5 0V8',
                    'stroke': 'currentColor',
                    'stroke-width': '1.5'
                }), _createElement('path', {
                    'd': 'M0.5 4L8.5 4',
                    'stroke': 'currentColor',
                    'stroke-width': '1.5'
                })), _createElement('div', { 'className': 'loading__spinner hidden' }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'className': 'spinner',
                    'viewBox': '0 0 66 66',
                    'xmlns': 'http://www.w3.org/2000/svg'
                }, _createElement('circle', {
                    'className': 'path',
                    'fill': 'none',
                    'stroke-width': '6',
                    'cx': '33',
                    'cy': '33',
                    'r': '30'
                })))) : null, !window.Convermax.customerCompanyId && !this.buyable ? _createElement('a', {
                    'href': '/pages/dealer-locator',
                    'className': 'quick-add__submit button btn-with-icon find-dealer-button',
                    'key': '6708'
                }, _createElement('svg', {
                    'width': '11',
                    'height': '12',
                    'viewBox': '0 0 11 12',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg'
                }, _createElement('circle', {
                    'cx': '4.75',
                    'cy': '5.25',
                    'r': '3.75',
                    'stroke': 'currentColor',
                    'strokeWidth': '1.5'
                }), _createElement('path', {
                    'd': 'M7.75 8.25L10 10.5',
                    'stroke': 'currentColor',
                    'strokeWidth': '1.5'
                })), '\n              Find a Dealer\n            ') : null) : null) : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []