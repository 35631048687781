//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-285:_1176,_4896,_6620,_4096,_6476,_5048,_7324,_76;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-285')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-285', "_1176,_4896,_6620,_4096,_6476,_5048,_7324,_76");
        }
      }catch (ex) {
        console.error(ex);
      }