import shopifyGenericDefaults from '../shopify-generic/config.js';
import { showModal, hideModal } from './quickViewDrawer.js';

shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchVehicleWidget').isAlwaysActive = true;
shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchVehicleWidget').isAlwaysColumnLayout = true;

window.Convermax.closeQuickView = () => {
  hideModal();
};

window.Convermax.quickView = (e) => {
  showModal(e);
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  product: {
    ...shopifyGenericDefaults.product,
    noImageSrc: window.Convermax?.config?.noImageSrc || 'https://client.convermax.com/static/img/noimage.png',
  },
  collapsibleAnimationTime: 100,
  pagination: {
    edgeRange: 1,
    centralRange: 2,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['SearchBox', 'SearchBoxDialogButton', 'Facets', 'SearchHeader'].includes(w.name),
    ),
    {
      name: 'FacetVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'SearchBox',
      type: 'SearchBox',
      location: { selector: '#cm-search-box', class: 'cm-search-box' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxMobile',
      type: 'SearchBox',
      location: {
        selector: '#cm-mobile-search-box',
        class: 'cm-mobile-search-box',
      },
      template: 'SearchBox',
      onSubmit: () => {
        const modalDialog = window.document.querySelector('.header__search details');
        if (modalDialog) {
          modalDialog.open = false;
          window.document.body.classList.remove('overflow-hidden');
        }
      },
    },
    {
      ...shopifyGenericDefaults.Widgets.find((w) => w.name === 'Facets'),
      initCollapsed: true,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'collection_sort': 'Featured Products',
        'title': 'Alphabetically, A-Z',
        'title:desc': 'Alphabetically, Z-A',
        'price': 'Price, low to high',
        'price:desc': 'Price, high to low',
        'published_at': 'Date, old to new',
        'published_at:desc': 'Date, new to old',
      },
    },
  ],
};
